import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import TextLong from './TextLong';
import { HttpImage } from './util/HttpConnection';


const GameItem = ({ item, doAction }) => {
    const [image, setImage] = useState();
    const { language } = useContext(LanguageContext);

    let history = useNavigate();

    useEffect(() => {
        //Load image
        if (item.frontPortrait)
            HttpImage(item.frontPortrait.id, result => setImage(result), error => console.log(error))
    }, [item.frontPortrait])


    return <Container width="12" sm="6" lg="4" px="4">
        <Card flex direction="column" shadowOn width="12" minheight="100%" color="color-white">
            <ContainerFlex expand width="12">
                <Container width="12">
                    <Card flex justify="center" width="12" radiusbl="0" radiusbr="0" height="250px" overflow="hidden">
                        <img height="100%" width="100%" alt='game-icon' src={image} />
                    </Card>
                    <Container width="12" ph="16" mt="16" fontSize="18px" fontWeight="bold" children={getTextLang(item, 'name', language)} />
                    <Container width="12" ph="16" fontSize="14px" pv="16" children={<TextLong text={item.description} lineLimit={4} />} />
                </Container>
            </ContainerFlex>
            <ContainerFlex width="12" align="center" pb="16">
                <Button onClick={doAction}
                    width="wrap" mh="8" pv="8" color="color-blue" hoverColor="color-blue-light"
                    fontColor="color-white" fontWeight="bold" textAlign="center"
                    children={<TextLocalized children={"actionEdit"} />} />

                <Button onClick={() => history("/questions", { state: { item: item } })}
                    width="wrap" mh="8" pv="8" color="color-coral" hoverColor="color-blue-light"
                    textTransform="uppercase"
                    fontColor="color-white" fontWeight="bold" textAlign="center"
                    children={<TextLocalized children={"msgDrawer4"} />} />
            </ContainerFlex>
        </Card>
    </Container>
};

export default GameItem;