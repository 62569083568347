import React, { useState, useContext } from 'react';

import es from '../../constants/es';

import flag_es from '../../img/flag/es.jpg';

export const LanguageData = {
  lang: "langLINQOZ"
}

export const dictionaryList = {
  es
};

export const languageOptions = [
  { id: 'es', text: 'es', image: flag_es },
  /*
  { id: 'en', text: 'en', image: flag_en },
  { id: 'bg', text: 'bg', image: flag_bg },
  { id: 'cs', text: 'cs', image: flag_cs },
  { id: 'de', text: 'de', image: flag_de },
  { id: 'fr', text: 'fr', image: flag_fr },
  { id: 'it', text: 'it', image: flag_it },
  { id: 'pl', text: 'pl', image: flag_pl },
  { id: 'ro', text: 'ro', image: flag_ro },
  { id: 'sv', text: 'sv', image: flag_sv },
  */
];

const getCookieLang = () => {
  let lang = localStorage.getItem(LanguageData.lang);
  if (lang && languageOptions.filter(language => language.id == lang).length > 0) {
    const selectedLang = languageOptions.filter(language => language.id == lang)[0];
    return { language: selectedLang, dictionary: dictionaryList[selectedLang.id] };
  }

  return {
    language: navigator.language.includes("es") ? languageOptions[0] : languageOptions[1],
    dictionary: dictionaryList[navigator.language.includes("es") ? languageOptions[0].id : languageOptions[1].id]
  }
}

export const LanguageContext = React.createContext(getCookieLang());

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage); // it will update the language in state
      setDictionary(dictionaryList[selectedLanguage.id]);
    },
  };

  return <LanguageContext.Provider value={provider} children={props.children} />;
};

export const getTextLang = (item, param, language) => {
  let value = item[`${param}${language.id !== 'en' ? `_${language.id.toUpperCase()}` : ''}`];
  return value == undefined ? item[param] : value;
}