import React, { Fragment, useContext, useEffect } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';

import logo from '../img/logo_white.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserAction, UserContext } from './providers/UserProvider';
import { LanguageContext } from './providers/LanguageProvider';

const DrawerLink = ({ label, fontColor, action }) => <Button onClick={action} width="12" ph="16" pv="8" mv="8" fontColor={fontColor} hoverColor="color-sidenav-link-hover" children={label} />

const DrawerSection = (attr) => {
    return <Card flex shadowOn shadow="0 -1px 0 var(--color-sidenav-line) inset" radius="0" {...attr} />
}

const Drawer = () => {

    const { dispatch, state } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    let history = useNavigate();
    let location = useLocation();

    useEffect(() => {
    }, [])

    const logOut = () => {
        dispatch({ action: UserAction.logout });
    }

    return (
        <ContainerFlex direction="column" width="250px" color="color-blue-drawer" fontColor="color-white" height="100%" maxheight="100%" overflowY="auto" pv="32">
            <DrawerSection width="250px" ph="32" pv="16">
                <img alt="efic-logo" src={logo} height="24px" />
                <Container ml="8" fontWeight="bold" children="LINQOZ" />
            </DrawerSection>

            {
                state.project ?
                    <DrawerSection width="250px" ph="32" pv="16">
                        <Container width="12" fontWeight="bold" children={<TextLocalized children="msgProject" />} />
                        <Container width="12" fontSize="14px" children={state.project.name} />
                    </DrawerSection>
                    : null
            }

            <DrawerSection width="12" direction="column" expand ph="16" pv="8">
                <Container width="12">
                    <DrawerLink label={dictionary["msgDrawer1"]} fontColor={location.pathname === "/" ? "color-green-accent" : "color-white"} action={() => history("/")} />
                    {
                        state.project ?
                            <Fragment>
                                <DrawerLink label={dictionary["msgDrawer2"]} fontColor={location.pathname.includes("games") ? "color-green-accent" : "color-white"} action={() => history("/games")} />
                                <DrawerLink label={dictionary["msgDrawer3"]} fontColor={location.pathname.includes("sections") ? "color-green-accent" : "color-white"} action={() => history("/sections")} />
                                <DrawerLink label={dictionary["msgDrawer4"]} fontColor={location.pathname.includes("questions") ? "color-green-accent" : "color-white"} action={() => history("/questions")} />
                            </Fragment>
                            : null
                    }
                </Container>
            </DrawerSection>

            <DrawerSection width="250px" ph="16" pv="16">
                <Button onClick={() => window.open("https://linqoz.es", "_blank")}
                    color="color-green-accent" hoverColor="color-white" pv="12" width="12" textAlign="center" fontSize="14px" fontColor="color-blue-accent" children={<TextLocalized children="msgDrawer8" />} />
            </DrawerSection>
            <DrawerSection ph="16" pv="8">
                <DrawerLink label={dictionary["msgDrawer5"]} fontColor={location.pathname.includes("about") ? "color-green-accent" : "color-white"} action={() => history("/about")} />
                <DrawerLink label={dictionary["msgDrawer6"]} fontColor={location.pathname.includes("profile") ? "color-green-accent" : "color-white"} action={() => history("/profile")} />
                <DrawerLink label={dictionary["msgDrawer7"]} action={logOut} />
            </DrawerSection>
        </ContainerFlex>
    );
};

export default Drawer;