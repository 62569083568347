import React, { useContext, useEffect, useState } from 'react';
import { HttpConnection, HttpMethod, HttpOperations } from './util/HttpConnection';
import { LanguageContext } from './providers/LanguageProvider';
import { UserAction, UserContext } from './providers/UserProvider';
import ButtonFlat from './ButtonFlat';
import { Button, Card, Container, ContainerFlex, CustomInput, SelectItemInput, TextLocalized } from './styled/Styled';
import { answerDefault } from '../constants/model';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';

const QuestionForm = ({ item, refreshItems }) => {

    const [formData, setFormData] = useState({ ...item });
    const [games, setGames] = useState([]);
    const [sections, setSections] = useState([]);
    const [languages, setLanguages] = useState([]);


    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch, state } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    /**
     * Load main info
     */
    useEffect(() => {
        //Get games details
        HttpConnection(`${HttpOperations.game}/page?page=0&count=100&columns=idEntity&values=${state.project.id}`,
            result => setGames(result), error => console.log(error), HttpMethod.get);

        //Load item detail
        if (item.id)
            HttpConnection(`${HttpOperations.question}/${item.id}`, result => {
                //Set id game
                result.idGame = result.category.game.id
                result.idSection = result.category.id
                setFormData(result)
            }, error => console.log(error), HttpMethod.get);

    }, [item, state.project])

    /**
     * Load sections
     */
    useEffect(() => {
        if (formData.idGame) {
            HttpConnection(`${HttpOperations.section}/${HttpOperations.game}/${formData.idGame}`,
                result => setSections(result), error => console.log(error), HttpMethod.get);

                HttpConnection(`${HttpOperations.game}/${formData.idGame}`,
                result => setLanguages(result.languages), error => console.log(error), HttpMethod.get);
        }
    }, [formData.idGame])

    /**
     * Send data to server
     */
    const sendRequest = () => {
        //Post item
        if (formData.question !== "" && formData.idSection !== "") {
            let formDataAux = { ...formData, category: { id: formData.idSection } };
            HttpConnection(`${HttpOperations.question}${formData.id ? `/${formData.id}` : ''}`,
                result => resultSuccess(),
                error => {
                    setIsSending(false);
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                }, formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const resultSuccess = () => {
        //Refresh data
        refreshItems();
        //Notify user
        dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
    }

    /**
     * Manage answers
     */
    const addAnswer = () => {
        setFormData({
            ...formData,
            answers: [...formData.answers, { ...answerDefault }]
        })
    }

    const deleteAnswer = (index) => {
        setFormData({
            ...formData,
            answers: formData.answers.filter((item, indexLocal) => indexLocal !== index)
        })
    }

    const onChangeAnswer = (name, value, index) => {
        let answerAux = [...formData.answers];
        answerAux[index][name] = value
        setFormData({
            ...formData,
            answers: answerAux
        })
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" mt="32" fontSize="26px" textAlign="left" children={<TextLocalized children="msgQuestionTitle" />} />

        <ContainerFlex width="12" ph="16" align="center">
            <SelectItemInput onChange={onChange} titleph="16" title={"msgGame"} placeholder={dictionary["actionSelectGame"]} name="idGame" value={formData.idGame ?? ""} options={games.map(game => {
                return { key: game.name, value: game.id };
            })} />
        </ContainerFlex>

        <ContainerFlex width="12" ph="16" mv="16" align="center">
            <SelectItemInput onChange={onChange} titleph="16" title={"msgSection"} placeholder={dictionary["actionSelectSection"]} name="idSection" value={formData.idSection ?? ""} options={sections.map(section => {
                return { key: section.name, value: section.id };
            })} />
        </ContainerFlex>

        <ContainerFlex width="12" ph="16" mb="16" align="center">
            <SelectItemInput onChange={onChange} titleph="16" title={"msgLanguage"} placeholder={dictionary["actionSelectLanguage"]} name="idLanguage" value={formData.idLanguage ?? ""} options={languages.map(language => {
                return { key: language.language.description, value: language.language.id };
            })} />
        </ContainerFlex>

        <Container width="12" ph="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgQuestion"} placeholder={dictionary["msgQuestion"]} type="text" name="question" value={formData.question} />} />

        <ContainerFlex width="12" px="16" mt="16">
            <Container width="wrap" fontWeight="bold" fontSize="18px" textAlign="left" textTransform="uppercase" children={<TextLocalized children="msgAnswers" />} />

            <Button onClick={addAnswer} mh="8" ph="32" pv="8" color="color-blue-accent" hoverColor="color-black-dark"
                fontColor="color-white" fontWeight="300" textAlign="center"
                children={<TextLocalized children={"actionAdd"} />} />
        </ContainerFlex>

        <ContainerFlex width="12" ph="16" mv="16" align="center">
            {
                formData.answers?.map((answer, index) => <ContainerFlex key={index} width="12" mv="8" align="stretch">
                    <ContainerFlex align="center">
                        <Button onClick={() => onChangeAnswer("correct", !answer.correct, index)} width="20px" height="20px" border="solid" borderColor="color-black" color={answer.correct ? "color-green-accent" : "color-white"} borderWidth="1px" radius="50%" />
                    </ContainerFlex>
                    <CustomInput onChange={(e) => onChangeAnswer(e.target.name, e.target.value, index)} titleph="16" inputmh="8" title={"msgAnswer"} placeholder={dictionary["msgAnswer"]} type="text" name="answer" value={answer.answer} />
                    <Button flex align="center" onClick={() => deleteAnswer(index)} ph="8" pv="8" color="color-red" hoverColor="color-red-dark"
                        fontColor="color-white" fontWeight="300" textAlign="center"
                        children={<Icon path={mdiTrashCan} size={1} />} />
                </ContainerFlex>)
            }
        </ContainerFlex>

        <ButtonFlat isSending={isSending} width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

export default QuestionForm;