import React, { useContext, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { verifyEmailFormat } from '../components/util/UserUtil';

import logo from '../img/logo_name.png';

const LoginView = () => {
    const [formData, setFormData] = useState({ username: "", password: "" })
    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.username !== "" && formData.password !== "") {
            if (verifyEmailFormat(formData.username)) {
                //Send request
                HttpConnection(HttpOperations.login,
                    result => {
                        if (result.usuario.type.id === 0) {
                            result.usuario.password = formData.password;
                            dispatch({ action: UserAction.login, data: result })
                            redirect("./")
                        } else {
                            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } })
                        }
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" px="32" minheight="calc(100vh - 80px)" justify="center" align="center" color="color-blue-accent">
            <Card minwidth="500px" ph="32" pv="64" shadowOn color="color-white">
                <img height="64px" alt='logo-linqoz' src={logo} />
                <Container mb="8" fontWeight="bold" fontSize="28px" children={<TextLocalized children="msgWelcome" />} />
                <Container mb="32" fontSize="16px" children={<TextLocalized children="msgWelcomeSubtitle" />} />

                <CustomInput onChange={onChange} width="12" inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="username" value={formData.username} />
                <CustomInput onChange={onChange} width="12" inputmv="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />

                <Button onClick={() => history("/register")} mt="32" ph="8" fontSize="12px" children={<TextLocalized children="msgNotRegister" />} />

                <ButtonFlat mt="48" action={sendRequest} label="actionLogin" fontColor="color-white" />
            </Card>
        </ContainerFlex>
    );
};

export default LoginView;