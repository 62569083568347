import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { LanguageContext } from '../components/providers/LanguageProvider';
import QuestionItem from '../components/QuestionItem';
import QuestionForm from '../components/QuestionForm';
import { questionDefault } from '../constants/model';

const QuestionsView = () => {

    const [games, setGames] = useState([]);
    const [sections, setSections] = useState([]);
    const [questions, setQuestions] = useState([]);
    const location = useLocation();

    const [filters, setFilters] = useState({ gameId: "", sectionId: "", search: "", page: 0 });

    const { dispatch, state } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    /**
     * Load games
     */
    useEffect(() => {
        if (location.state)
            setFilters({
                search: "", page: 0,
                gameId: location.state?.item?.id ?? "",
                sectionId: location.state?.section?.id ?? ""
            })

        HttpConnection(`${HttpOperations.game}/page?page=0&count=100&columns=idEntity&values=${state.project.id}`,
            result => setGames(result), error => console.log(error), HttpMethod.get);
    }, [state.project, location.state])

    /**
     * Load sections
     */
    useEffect(() => {
        if (filters.gameId) {
            HttpConnection(`${HttpOperations.section}/${HttpOperations.game}/${filters.gameId}`,
                result => setSections(result), error => console.log(error), HttpMethod.get);
        }
    }, [filters.gameId])

    /**
     * Load questions
     */
    const getItems = useCallback(() => {
        if (filters.gameId) {
            const url = filters.sectionId === "" ? `${HttpOperations.question}/page?page=${filters.page}&count=10&columns=game,question&values=${filters.gameId},${filters.search}`
                : `${HttpOperations.question}/page?page=${filters.page}&count=10&columns=category,question&values=${filters.sectionId},${filters.search}`
            HttpConnection(url, result => setQuestions(result), error => console.log(error), HttpMethod.get);
        }
    }, [filters]);

    useEffect(() => {
        getItems();
    }, [getItems]);

    const onChangeFilter = (e) => {
        setFilters({
            ...filters,
            page: 0,
            [e.target.name]: e.target.value
        })
    }

    const editItem = (e, item) => {
        e.stopPropagation();
        dispatch({ action: UserAction.showModal, data: { show: true, body: <QuestionForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <QuestionForm refreshItems={getItems} item={{ ...questionDefault, idGame: filters.gameId, idSection: filters.sectionId }} /> } });
    }

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <ContainerFlex width="12" justify="space-between" align="center">
                    <Container fontSize="22px" fontWeight="300" fontColor="color-black" textTransform="uppercase" children={<TextLocalized children="msgDrawer4" />} />

                    <Button onClick={addItem} mh="8" ph="32" pv="8" color="color-blue-accent" hoverColor="color-black-dark"
                        fontColor="color-white" fontWeight="300" textAlign="center"
                        children={<TextLocalized children={"actionNew"} />} />
                </ContainerFlex>

                <Card flex width="12" mv="16" px="16" color="color-blue">

                    <Card width="wrap" px="8" mr="4" color="color-white">
                        <select onChange={onChangeFilter} name='gameId' value={filters.gameId}>
                            <option value="" children={dictionary["actionSelectGame"]} />
                            {
                                games.map(item => <option value={item.id} key={item.id} children={item.name} />)
                            }
                        </select>
                    </Card>

                    <Card width="wrap" px="8" mh="4" color="color-white">
                        <select onChange={onChangeFilter} name='sectionId' value={filters.sectionId}>
                            <option value="" children={dictionary["actionSelectSection"]} />
                            {
                                sections.map(item => <option value={item.id} key={item.id} children={item.name} />)
                            }
                        </select>
                    </Card>

                    <Card width="wrap" px="8" ml="4" color="color-white">
                        <input type='text' name='search' onChange={onChangeFilter} placeholder={dictionary["actionSearch"]} />
                    </Card>
                </Card>

                <ContainerFlex width="12" align="stretch" children={questions.map((item) => <QuestionItem key={item.id} item={item} doAction={(e) => editItem(e, item)} />)} />

                {
                    filters.gameId !== "" ?
                        <ContainerFlex pv="32" justify="center">
                            {
                                filters.page > 0 ?
                                    <Button shadowOn onClick={() => setFilters({ ...filters, page: filters.page - 1 })}
                                        color="color-black" hoverColor="color-black-dark" pv="16" mh="4" width="wrap" textAlign="center" fontSize="14px" fontColor="color-white"
                                        children={<TextLocalized children="actionPrev" />} />
                                    : null
                            }
                            {
                                questions.length !== 0 ?
                                    <Button shadowOn onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
                                        color="color-black" hoverColor="color-black-dark" pv="16" mh="4" width="wrap" textAlign="center" fontSize="14px" fontColor="color-white"
                                        children={<TextLocalized children="actionNext" />} />
                                    : null
                            }
                        </ContainerFlex>
                        : null
                }
            </Container>
        </ContainerFlex>
    );
};

export default QuestionsView;