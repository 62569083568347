import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, ContainerFlex, TextHtml, TextLocalized } from './styled/Styled';


const TextLong = ({ text, lineLimit }) => {
    const [showAll, setShowAll] = useState(false);
    const [showLines, setShowLines] = useState({ lineHeight: 0, lines: 0 });
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            const divHeight = divRef.current.offsetHeight;
            const lineHeight = 1.2;
            const lineas = Math.round(divHeight / lineHeight);

            setShowLines({ lineHeight: lineHeight, lines: lineas });
        }
    }, [text]);

    const contenido = showAll ? text : text.split('\n').slice(0, lineLimit).join('\n');

    return (
        <Fragment>
            <div style={{ maxHeight: showAll ? 'none' : lineLimit * showLines.lineHeight + 'em', overflow: 'hidden' }}>
                <TextHtml innerRef={divRef} children={contenido} />
            </div>

            {
                lineLimit < showLines.lines ?
                    <ContainerFlex width="12" mt="8" justify="flex-end">
                        <Button fontColor="color-blue-dark" fontSize="12px" onClick={() => setShowAll(!showAll)} children={<TextLocalized children={showAll ? "actionShowLess" : "actionShowMore"} />} />
                    </ContainerFlex>

                    : null
            }
        </Fragment>

    );
};

export default TextLong;