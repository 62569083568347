import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import BlockItem from '../components/BlockItem';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { LanguageContext } from '../components/providers/LanguageProvider';

const EntityProjectsView = () => {
    const [quiz, setQuiz] = useState([]);
    const [search, setSearch] = useState("");

    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    let history = useNavigate();

    useEffect(() => {
        HttpConnection(`${HttpOperations.entity}/page?page=0&count=10&columns=&values=`,
            result => setQuiz(result), error => console.log(error), HttpMethod.get);
    }, [])

    const doAction = (item) => {
        dispatch({action: UserAction.project, data: item});
        history("/games")
    }

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">
                <Container width="12" fontSize="22px" fontWeight="300" fontColor="color-black" textTransform="uppercase" children={<TextLocalized children="msgProjects" />} />
                <Card width="12" mv="16" px="16" color="color-blue">

                    <Card width="12" px="8" color="color-white">
                        <input type='text' onChange={e => setSearch(e.target.value)} placeholder={dictionary["actionSearch"]} />
                    </Card>
                </Card>

                <ContainerFlex width="12" align="stretch" children={quiz.filter(item => item.name.toUpperCase().includes(search.toUpperCase())).map((item) => <BlockItem key={item.id_block} item={item} doAction={() => doAction(item)} />)} />
            </Container>
        </ContainerFlex>
    );
};

export default EntityProjectsView;