import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { LanguageContext } from '../components/providers/LanguageProvider';
import SectionItem from '../components/SectionItem';
import SectionForm from '../components/SectionForm';
import { sectionDefault } from '../constants/model';

const SectionsView = () => {
    const [games, setGames] = useState([]);
    const [sections, setSections] = useState([]);

    const [filters, setFilters] = useState({ gameId: "", search: "" });

    const { dispatch, state } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    const getItems = () => {
        if (filters.gameId)
            HttpConnection(`${HttpOperations.section}/${HttpOperations.game}/${filters.gameId}`,
                result => setSections(result), error => console.log(error), HttpMethod.get);
    }

    useEffect(() => {
        HttpConnection(`${HttpOperations.game}/page?page=0&count=100&columns=idEntity&values=${state.project.id}`,
            result => setGames(result), error => console.log(error), HttpMethod.get);
    }, [state.project])

    useEffect(() => {
        if (filters.gameId)
            HttpConnection(`${HttpOperations.section}/${HttpOperations.game}/${filters.gameId}`,
                result => setSections(result), error => console.log(error), HttpMethod.get);
    }, [filters.gameId])

    const onChangeFilter = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    const editItem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <SectionForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <SectionForm refreshItems={getItems} item={{ ...sectionDefault, idGame: filters.gameId }} /> } });
    }

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">

                <ContainerFlex width="12" justify="space-between" align="center">
                    <Container fontSize="22px" fontWeight="300" fontColor="color-black" textTransform="uppercase" children={<TextLocalized children="msgDrawer3" />} />

                    <Button onClick={addItem} mh="8" ph="32" pv="8" color="color-blue-accent" hoverColor="color-black-dark"
                        fontColor="color-white" fontWeight="300" textAlign="center"
                        children={<TextLocalized children={"actionNew"} />} />
                </ContainerFlex>

                <Card flex width="12" mv="16" px="16" color="color-blue">

                    <Card width="wrap" px="8" mr="4" color="color-white">
                        <select onChange={onChangeFilter} name='gameId' value={filters.gameId}>
                            <option value="" children={<TextLocalized children="actionSelectGame" />} />
                            {
                                games.map(item => <option value={item.id} key={item.id} children={item.name} />)
                            }
                        </select>
                    </Card>

                    <Card width="wrap" px="8" ml="4" color="color-white">
                        <input type='text' name='search' value={filters.search} onChange={onChangeFilter} placeholder={dictionary["actionSearch"]} />
                    </Card>
                </Card>

                <ContainerFlex width="12" align="stretch" children={sections.filter(item => item.name.toUpperCase().includes(filters.search.toUpperCase())).map((item) => <SectionItem key={item.id} item={item} doAction={() => editItem(item)} />)} />
            </Container>
        </ContainerFlex>
    );
};

export default SectionsView;