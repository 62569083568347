import { UserData } from '../providers/UserProvider';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';

export const checkLoginUser = (user) => {
    var storageUser = getStorageUser();
    return storageUser && storageUser.id_user === user.id_user;
}

export const updateStorageUser = (data) => {
    var local = getStorageUser()
    data.token = local.token;

    var encrypted = AES.encrypt(JSON.stringify(data), `${process.env.REACT_APP_SEC_KEY}`);
    localStorage.setItem(UserData.user, encrypted);
}

export const saveStorageUser = (item, key = UserData.user) => {
    var encrypted = AES.encrypt(JSON.stringify(item), `${process.env.REACT_APP_SEC_KEY}`);
    localStorage.setItem(key, encrypted);
}

export const getStorageUser = (key = UserData.user) => {
    var encrypted = localStorage.getItem(key);
    if (encrypted) {
        try {
            var bytes = AES.decrypt(encrypted, `${process.env.REACT_APP_SEC_KEY}`);
            var item = JSON.parse(bytes.toString(encUTF8))
            return item ? item : null
        } catch (error) {
            console.log(error)
            localStorage.removeItem(key)
            console.error("unknow user")
        }
    }
    return null;
}

export const getUserToken = () => {
    var storageUser = getStorageUser();
    return (storageUser) ? storageUser.token : "";
}

export const verifyEmailFormat = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}