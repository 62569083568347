const es = {
    actionAlready: "¿Ya tienes una cuenta? Iniciar sesión",
    actionExit: "Cerrar sesión",
    actionLogin: "INICIAR SESIÓN",
    actionRegister: "REGÍSTRATE",
    actionSave: "GUARDAR",
    actionStart: "COMENZAR",
    actionContinue: "CONTINUAR",
    actionSelect: "SELECCIONAR",
    actionYes: "Sí",
    actionNo: "No",
    actionNext: "SIGUIENTE",
    actionOpenUrl: "ABRIR URL",
    actionFilter: "FILTRAR",
    actionShowLess: "MOSTRAR MENOS",
    actionShowMore: "MOSTRAR MAS",
    actionEdit: "EDITAR",
    actionSearch: "Buscar",
    actionSelectGame: "Seleccionar juego",
    actionSelectSection: "Seleccionar sección",
    actionSelectLanguage: "Seleccionar idioma",
    actionPrev: "ANTERIOR",
    actionDelete: "ELIMINAR",
    actionNew: "NUEVO",
    actionAdd: "AÑADIR",
    
    /** HEADER */
    msgHeader1: "Linqoz",
    msgHeader2: "Futbolinqoz",

    msgHeader4: "Inicio de sesión",
    
    /** DRAWER */
    msgUser: "USUARIO",
    msgDrawer1: "Proyectos",
    msgDrawer2: "Juegos",
    msgDrawer3: "Secciones",
    msgDrawer4: "Preguntas",
    msgDrawer5: "Ayuda",
    msgDrawer6: "Ajustes",
    msgDrawer7: "Cerrar sesión",
    msgDrawer8: "Contacta con soporte",
    
    /** LOGIN */
    msgWelcome: "Bienvenido/a",
    msgWelcomeSubtitle: "Inicia sesión para continuar",
    
    /** REGISTER */
    msgGetStarted: "Cómo empezar",
    msgGetStartedSubtitle: "Esta herramienta de gestión del impacto es gratuita y confidencial",
    
    msgFirstname: "Nombre",
    msgLastname: "Apellidos",
    msgEmail: "Correo electrónico",
    msgPassword: "Contraseña",
    msgConfirmPassword: "Confirmar contraseña",
    msgCompanyUser: "Empresa o nombre de usuario",
    
    msgNotRegister: "¿Aún no te has registrado? <b>Regístrate aquí</b>",
    msgAlreadyRegister: "¿Ya estás registrado? <b>Accede aquí</b>",

    /** GAME */
    msgGameTitle: "Crear/Editar juego",
    msgName: "Nombre",
    msgTags: "Etiquetas",
    msgFeatured: "Destacado",
    msgDate: "Fecha",
    msgDescription: "Descripción",
    msgSelectFile: "Selecciona una imagen",

    /** SECTION */
    msgSectionTitle: "Crear/Editar sección",
    msgGame: "Juego",

    /** QUESTION */
    msgQuestionTitle: "Crear/Editar pregunta",
    msgSection: "Sección",
    msgQuestion: "Pregunta",
    msgAnswers: "Respuestas",
    msgAnswer: "Respuesta",
    msgLanguage: "Idioma",

    /** SETTINGS */
    msgUserInfo: "Información de usuario",
    msgUserCredentials: "Credenciales de usuario",
    
    /** MODAL */
    msgAreSure: "¿Estás seguro?",
    
    msgSuccess: "Datos actualizados correctamente",
    msgDeleteSuccess: "Datos eliminados correctamente",
    
    /** ERROR */
    errorOblData: "Completa todos los campos obligatorios",
    errorEmailFormat: "Formato de correo electrónico incorrecto",
    errorUserIncorrect: "Correo electrónico o contraseña incorrectos",
    errorSend: "Error de comunicación, espere y vuelva a intentarlo más tarde",
    errorLength: "Se ha superado el número de caracteres",
    errorPassSame: "Las contraseñas no coinciden",
    errorDateFormat: "Formato de fecha incorrecto",
    
    /** FOOTER */
    msgFooterContact: "CONTACTO",
    msgFooterLinkedIn: "LINKEDIN",
    msgFooterTwitter: "TWITTER",
    
    msgFooterGr: "Powered by",
    msgCopyright: "Copyright&copy;2023 HUBIQUS CREATIVE SOLUTIONS. Todos los derechos reservados",
    
    msgFooterLegal: "Aviso legal",
    msgFooterPrivacy: "Privacidad",
    msgFooterCookies: "Política de cookies",
    
    /* COUNTRIES */
    msgCountry: "País",
    msgAfghanistan: "Afganistán",
    msgAlbania: "Albania",
    msgAlgeria: "Argelia",
    msgAmericanSamoa: "Samoa Americana",
    msgAndorra: "Andorra",
    msgAngola: "angola",
    msgAnguilla: "anguila",
    msgAntarctica: "Antártida",
    msgAntiguaandBarbuda: "Antigua y Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaiyán",
    msgBahamas: "bahamas",
    msgBahrain: "Baréin",
    msgBangladesh: "bangladesh",
    msgBarbados: "barbados",
    msgBelarus: "Bielorrusia",
    msgBelgium: "Bélgica",
    msgBelize: "Belice",
    msgBenin: "Benín",
    msgBermuda: "islas Bermudas",
    msgBhutan: "Bután",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia y Herzegovina",
    msgBotswana: "Botsuana",
    msgBouvetIsland: "Isla Bouvet",
    msgBrazil: "Brasil",
    msgBritishIndian: "Territorio Británico del Océano Índico",
    msgBrunei: "Brunéi",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Camboya",
    msgCameroon: "Camerún",
    msgCanada: "Canadá",
    msgCapeVerde: "Cabo Verde",
    msgCaymanIslands: "Islas Caimán",
    msgCentralAfrican: "República Centroafricana",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "Porcelana",
    msgChristmasIsland: "Isla de Navidad",
    msgCocosIslands: "Islas Cocos (Keeling)",
    msgColombia: "Colombia",
    msgComoros: "Comoras",
    msgCongo: "Congo",
    msgRepublicCongo: "La República Democrática del Congo",
    msgCookIslands: "Islas Cook",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Costa de Marfil",
    msgCroatia: "Croacia",
    msgCuba: "Cuba",
    msgCyprus: "Chipre",
    msgCzechRepublic: "República Checa",
    msgDenmark: "Dinamarca",
    msgDjibouti: "Yibuti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "República Dominicana",
    msgEastTimor: "Timor Oriental",
    msgEcuador: "Ecuador",
    msgEgypt: "Egipto",
    msgEngland: "Inglaterra",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Guinea Ecuatorial",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Etiopía",
    msgFalklandIslands: "Islas Malvinas",
    msgFaroeIslands: "Islas Faroe",
    msgFijiIslands: "Islas Fiyi",
    msgFinland: "Finlandia",
    msgFrance: "Francia",
    msgFrenchGuiana: "Guayana Francesa",
    msgFrenchPolynesia: "Polinesia francés",
    msgFrenchSouthern: "Territorios Franceses del Sur",
    msgGabon: "Gabón",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Alemania",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grecia",
    msgGreenland: "Groenlandia",
    msgGrenada: "Granada",
    msgGuadeloupe: "Guadalupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernesey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bisáu",
    msgGuyana: "Guayana",
    msgHaiti: "Haití",
    msgHeardMcDonald: "Islas Heard y McDonald",
    msgHolySee: "Santa Sede (Estado de la Ciudad del Vaticano)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungría",
    msgIceland: "Islandia",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Irán",
    msgIraq: "Irak",
    msgIreland: "Irlanda",
    msgIsrael: "Israel",
    msgIsleMan: "Isla del hombre",
    msgItaly: "Italia",
    msgJamaica: "Jamaica",
    msgJapan: "Japón",
    msgJersey: "Jersey",
    msgJordan: "Jordán",
    msgKazakhstan: "Kazajstán",
    msgKenya: "Kenia",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kirguistán",
    msgLaos: "Laos",
    msgLatvia: "letonia",
    msgLebanon: "Líbano",
    msgLesotho: "Lesoto",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Jamahiriya Árabe Libia",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lituania",
    msgLuxembourg: "luxemburgo",
    msgMacao: "Macao",
    msgNorthMacedonia: "macedonia del norte",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malaui",
    msgMalaysia: "Malasia",
    msgMaldives: "Maldivas",
    msgMali: "Malí",
    msgMalta: "Malta",
    msgMarshallIslands: "Islas Marshall",
    msgMartinique: "Martinica",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauricio",
    msgMayotte: "Mayotte",
    msgMexico: "México",
    msgMicronesia: "Micronesia, Estados Federados de",
    msgMoldova: "Moldavia",
    msgMonaco: "Mónaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "montenegro",
    msgMorocco: "Marruecos",
    msgMozambique: "Mozambique",
    msgMyanmar: "Birmania",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Países Bajos",
    msgNetherlandsAntilles: "Antillas Holandesas",
    msgNewCaledonia: "Nueva Caledonia",
    msgNewZealand: "Nueva Zelanda",
    msgNicaragua: "Nicaragua",
    msgNiger: "Níger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Isla Norfolk",
    msgNorthKorea: "Corea del Norte",
    msgNorthernIreland: "Irlanda del Norte",
    msgNorthernMariana: "Islas Marianas del Norte",
    msgNorway: "Noruega",
    msgOman: "Omán",
    msgPakistan: "Pakistán",
    msgPalau: "palaos",
    msgPalestine: "Palestina",
    msgPanama: "Panamá",
    msgPapua: "Papúa Nueva Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Perú",
    msgPhilippines: "Filipinas",
    msgPitcairn: "pitcairn",
    msgPoland: "Polonia",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Katar",
    msgReunion: "Reunión",
    msgRomania: "Rumania",
    msgRussianFederation: "Federación Rusa",
    msgRwanda: "Ruanda",
    msgSaintHelena: "Santa Elena",
    msgSaintKitts: "San Cristóbal y Nieves",
    msgSaintLucia: "Santa Lucía",
    msgSaintPierre: "San Pedro y Miquelón",
    msgSaintVincent: "San Vicente y las Granadinas",
    msgSamoa: "samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Santo Tomé y Príncipe",
    msgSaudiArabia: "Arabia Saudita",
    msgScotland: "Escocia",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leona",
    msgSingapore: "Singapur",
    msgSlovakia: "Eslovaquia",
    msgSlovenia: "Eslovenia",
    msgSolomonIslands: "Islas Salomón",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Sudáfrica",
    msgSouthSandwich: "Georgia del sur y las islas Sandwich del sur",
    msgSouthKorea: "Corea del Sur",
    msgSouthSudan: "Sudán del Sur",
    msgSpain: "España",
    msgSriLanka: "Sri Lanka",
    msgSudan: "Sudán",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard y Jan Mayen",
    msgSwaziland: "Suazilandia",
    msgSweden: "Suecia",
    msgSwitzerland: "Suiza",
    msgSyria: "Siria",
    msgTajikistan: "Tayikistán",
    msgTanzania: "Tanzania",
    msgThailand: "Tailandia",
    msgTimorLeste: "Timor Oriental",
    msgTogo: "Ir",
    msgTokelau: "Tokelau",
    msgTonga: "tonga",
    msgTrinidadTobago: "Trinidad y Tobago",
    msgTunisia: "Túnez",
    msgTurkey: "Pavo",
    msgTurkmenistan: "turkmenistán",
    msgTurksCaicos: "Islas Turcas y Caicos",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ucrania",
    msgUnitedArabEmirates: "Emiratos Árabes Unidos",
    msgUnitedKingdom: "Reino Unido",
    msgUnitedStates: "Estados Unidos",
    msgUnitedStatesMinor: "Islas ultramarinas menores de los Estados Unidos",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistán",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Islas Vírgenes Británicas",
    msgVirginIslandsUS: "Islas Vírgenes, EE. UU.",
    msgWales: "Gales",
    msgWallisFutuna: "Wallis y Futuna",
    msgWesternSahara: "Sahara Occidental",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabue",
}

export default es;