import React, { useState, useEffect, Fragment } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import { HttpConnection, HttpMethod, HttpOperations } from './util/HttpConnection';

const SingleAnswer = ({ item }) => {
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        setAnswers([...item]);
    }, [item])

    const onChange = (e) => {
        let answersAux = answers.map(answer => {
            if (parseInt(e.target.value) === answer.id)
                answer.correct = !answer.correct
            return answer;
        })
        setAnswers(answersAux)
    }

    return <Container width="12" ph="8" fontColor="color-black">
        {
            answers.map(answer => <ContainerFlex key={answer.id} width="12" mv="16" align="center">
                <input type="radio"
                    checked={answer.correct}
                    onClick={onChange}
                    id={answer.id}
                    disabled
                    value={answer.id} />

                <Container ml="8" children={<label htmlFor={answer.id} children={answer.answer} />} />
            </ContainerFlex>)
        }
    </Container>
}


const QuestionItem = ({ item, doAction }) => {

    const [answers, setAnswers] = useState([]);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        if (showDetails)
            HttpConnection(`${HttpOperations.question}/${item.id}`,
                result => { setAnswers(result.answers) },
                error => console.log(error), HttpMethod.get)
    }, [showDetails, item.id])

    return <Card shadowOn width="12" mt="16" color="color-white">
        <Button onClick={() => setShowDetails(!showDetails)} flex width="12" px="16" align="center" color={showDetails ? "color-gray-card" : ""} radiusbl="0px" radiusbr="0px">
            <Container width="wrap" textAlign="left" fontSize="16px" fontWeight="300" fontColor="color-black" children={item.question} />

            <Button onClick={doAction} mh="8" ph="16" pv="8" color="color-blue" hoverColor="color-gray-dark" fontWeight="300" fontColor="color-white" fontSize="12px" children={<TextLocalized children="actionEdit" />} />
            <Container maxheight="24px" children={<Icon size={1} path={showDetails ? mdiChevronUp : mdiChevronDown} />} />
        </Button>
        {
            showDetails ?
                <Fragment>
                    <Container width="12" height="1px" color="color-gray" />
                    <ContainerFlex width="12" px="16">
                        <SingleAnswer item={answers} />
                    </ContainerFlex>
                </Fragment>
                : null
        }
    </Card>
};


export default QuestionItem;