import React, { useContext, useEffect, useRef, useState } from 'react';
import { HttpConnection, HttpFormData, HttpMethod, HttpOperations } from './util/HttpConnection';
import { LanguageContext } from './providers/LanguageProvider';
import { UserAction, UserContext } from './providers/UserProvider';
import ButtonFlat from './ButtonFlat';
import { AreaInput, Button, Card, Container, ContainerFlex, CustomInput, TextLocalized } from './styled/Styled';
import { format, parse } from 'date-fns'


const GameForm = ({ item, refreshItems }) => {

    const [formData, setFormData] = useState({ ...item });
    const [attach, setAttach] = useState();

    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch, state } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const inputRef = useRef();

    useEffect(() => {
        //Get installation details
        if (item.id)
            HttpConnection(`${HttpOperations.game}/${item.id}`, result => {
                //Change update date format
                if (result.updateDate) {
                    try {
                        setFormData({
                            ...result,
                            updateDate: format(new Date(result.updateDate), 'dd/MM/yyyy HH:mm')
                        })
                    } catch (e) {
                        console.log("ERROR DATE FORMAT");
                        setFormData(result)
                    }
                }
            }, error => console.log(error), HttpMethod.get);
    }, [item])

    const sendRequest = () => {
        //Post item
        if (formData.name !== "" && formData.description) {
            try {
                let formDataAux = {
                    ...formData,
                    idEntity: state.project.id,
                    updateDate: parse(formData.updateDate, 'dd/MM/yyyy HH:mm', new Date()).getTime()
                };
                if (attach) {
                    sendImage(formDataAux)
                } else {
                    HttpConnection(`${HttpOperations.game}${formData.id ? `/${formData.id}` : ''}`,
                        result => resultSuccess(),
                        error => {
                            setIsSending(false);
                            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                        }, formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
                }
            } catch (e) {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorDateFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const sendImage = (item) => {
        HttpFormData(`${HttpOperations.game}${formData.id ? `/${formData.id}` : ''}/form`,
            result => resultSuccess(),
            error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
            item.id ? HttpMethod.put : HttpMethod.post, item, [{ key: "portrait", value: attach }]);
    }

    const resultSuccess = () => {
        //Refresh data
        refreshItems();
        //Notify user
        dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
    }

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                URL.revokeObjectURL(image.src);
                setAttach({ file: file, url: reader.result });
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    const deleteAttach = () => {
        setAttach(null);
        setFormData({ ...formData, frontPortrait: null, })
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" mt="32" fontSize="26px" textAlign="left" children={<TextLocalized children="msgGameTitle" />} />
        <ContainerFlex width="12" ph="16" pb="16" mt="16">
            <Button onClick={() => setFormData({ ...formData, featured: !formData.featured })} width="20px" height="20px" border="solid" borderColor="color-black" color={formData.featured ? "color-green-accent" : "color-white"} borderWidth="1px" radius="50%" />
            <Button onClick={() => setFormData({ ...formData, featured: !formData.featured })} ml="8" children={<TextLocalized children="msgFeatured" />} />
        </ContainerFlex>
        <Container width="12" ph="16" pb="16" mt="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="name" value={formData.name} />} />
        <Container width="12" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgTags"} placeholder={dictionary["msgTags"]} type="text" name="tags" value={formData.tags} />} />
        <Container width="12" ph="16" pb="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgDate"} placeholder={dictionary["msgDate"]} type="text" name="updateDate" value={formData.updateDate} />} />

        <Container width="12" ph="16" pb="16" children={<AreaInput onChange={onChange} titleph="16" title={"msgDescription"} placeholder={dictionary["msgDescription"]} type="text" name="description" value={formData.description ?? ""} />} />

        <ContainerFlex width="12" ph="16" align="center">
            <Container pv="16" children={<TextLocalized children="msgSelectFile" />} />
            {
                formData.frontPortrait || attach
                    ? <Button onClick={deleteAttach} mh="16" ph="16" pv="8" justify="center" align="center" color="color-black" fontColor="color-white" letterSpacing="1px" children={<TextLocalized children="actionDelete" />} />
                    : <Button onClick={() => inputRef.current.click()} mh="16" ph="16" pv="8" justify="center" align="center" color="color-white" fontColor="color-black" letterSpacing="1px" children={<TextLocalized children="actionSelect" />} />
            }
            <input onChange={handleImageChange} ref={inputRef} hidden type="file" />
        </ContainerFlex>
        <ButtonFlat isSending={isSending} width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

export default GameForm;