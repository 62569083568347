import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';

import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import GameItem from '../components/GameItem';
import { LanguageContext } from '../components/providers/LanguageProvider';
import GameForm from '../components/GameForm';
import { gameDefault } from '../constants/model';

const GamesView = () => {

    const [quiz, setQuiz] = useState([]);
    const [search, setSearch] = useState("");

    const { dispatch, state } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    const getItems = () => {
        HttpConnection(`${HttpOperations.game}/page?page=0&count=100&columns=idEntity&values=${state.project.id}&order=name`,
            result => setQuiz(result), error => console.log(error), HttpMethod.get);
    }

    useEffect(() => {
        HttpConnection(`${HttpOperations.game}/page?page=0&count=100&columns=idEntity&values=${state.project.id}&order=name`,
            result => setQuiz(result), error => console.log(error), HttpMethod.get);
    }, [state.project])

    const editItem = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <GameForm refreshItems={getItems} item={item} /> } });
    }

    const addItem = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <GameForm refreshItems={getItems} item={{ ...gameDefault }} /> } });
    }

    return (
        <ContainerFlex width="12" justify="center" px="32" >
            <Container width="12" lg="8">

                <ContainerFlex width="12" justify="space-between" align="center">
                    <Container fontSize="22px" fontWeight="300" fontColor="color-black" textTransform="uppercase" children={<TextLocalized children="msgDrawer2" />} />

                    <Button onClick={addItem} mh="8" ph="32" pv="8" color="color-blue-accent" hoverColor="color-black-dark"
                        fontColor="color-white" fontWeight="300" textAlign="center"
                        children={<TextLocalized children={"actionNew"} />} />
                </ContainerFlex>
                <Card width="12" mv="16" px="16" color="color-blue">

                    <Card width="12" px="8" color="color-white">
                        <input type='text' onChange={e => setSearch(e.target.value)} placeholder={dictionary["actionSearch"]} />
                    </Card>
                </Card>

                <ContainerFlex width="12" align="stretch" children={quiz.filter(item => item.name.toUpperCase().includes(search.toUpperCase())).map((item) => <GameItem key={item.id} item={item} doAction={() => editItem(item)} />)} />
            </Container>
        </ContainerFlex>
    );
};

export default GamesView;