import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { CategoryIndexProvider } from './components/providers/CategoryIndexProvider';

import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container, ContainerFlex, LanguageSelector } from './components/styled/Styled';
import Header from './components/Header';
import { Footer } from './components/Footer';

import EntityProjectsView from './views/EntityProjectsView';
import Drawer from './components/Drawer';
import LoginView from './views/LoginView';
import GamesView from './views/GamesView';
import SectionsView from './views/SectionsView';
import QuestionsView from './views/QuestionsView';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" height="100vh" overflowY="auto" color="color-white">
      <Router basename="">
        <Fragment>
          {
            state.login ?
              <ContainerFlex width="12" height="100vh" color="color-gray">
                <Drawer />
                <Container width="wrap" maxheight="100%" overflowY="auto">
                  <ContainerFlex width="12" px="16" justify="flex-end" children={<LanguageSelector />} />
                  <Routes>
                    <Route exact={true} path="/" element={<EntityProjectsView />} />
                    <Route exact={true} path="/games" element={<GamesView />} />
                    <Route exact={true} path="/sections" element={<SectionsView />} />
                    <Route exact={true} path="/questions" element={<QuestionsView />} />

                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </Container>
              </ContainerFlex>
              :
              <Fragment>
                <Header />
                <Routes>
                  <Route exact={true} path="/" element={<LoginView />} />
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
                <Footer />
              </Fragment>
          }
        </Fragment>
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <CategoryIndexProvider children={<LanguageProvider children={<UserProvider children={<SwitchView />} />} />} />

export default App;
