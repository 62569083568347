import React, { useContext } from 'react';
import { getTextLang, LanguageContext } from './providers/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';


const BlockItem = ({ item, doAction }) => {
    const { language } = useContext(LanguageContext);

    return <Container width="12" sm="6" lg="4" px="4">
        <Card flex direction="column" shadowOn width="12" minheight="100%" color="color-white">
            <ContainerFlex expand width="12">
                <Container width="12">
                    <Container width="12" ph="16" mt="16" fontSize="18px" fontWeight="bold" children={getTextLang(item, 'name', language)}  />
                    <Container width="12" ph="16" fontSize="14px" pv="16" children={getTextLang(item, 'sku', language)} />
                </Container>
            </ContainerFlex>
            <ContainerFlex width="12" align="center" pb="16">
                <Button onClick={doAction}
                    width="wrap" mh="8" pv="8" color="color-blue" hoverColor="color-blue-light"
                    fontColor="color-white" fontWeight="bold" textAlign="center"
                    children={<TextLocalized children={"actionSelect"} />} />
            </ContainerFlex>
        </Card>
    </Container>
};

export default BlockItem;