import React, { useContext, useEffect, useState } from 'react';
import { HttpConnection, HttpMethod, HttpOperations } from './util/HttpConnection';
import { LanguageContext } from './providers/LanguageProvider';
import { UserAction, UserContext } from './providers/UserProvider';
import ButtonFlat from './ButtonFlat';
import { AreaInput, Card, Container, ContainerFlex, CustomInput, SelectItemInput, TextLocalized } from './styled/Styled';

const SectionForm = ({ item, refreshItems }) => {

    const [formData, setFormData] = useState({ ...item });
    const [games, setGames] = useState([]);

    const [isSending, setIsSending] = useState(false);

    const { dictionary } = useContext(LanguageContext);
    const { dispatch, state } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(() => {
        //Get installation details
        HttpConnection(`${HttpOperations.game}/page?page=0&count=100&columns=idEntity&values=${state.project.id}`,
            result => setGames(result), error => console.log(error), HttpMethod.get);
        //Load item detail
        if (item.id)
            HttpConnection(`${HttpOperations.section}/${item.id}`, result => {
                //Set id game
                result.idGame = result.game.id
                setFormData(result)
            }, error => console.log(error), HttpMethod.get);
    }, [item, state.project])

    const sendRequest = () => {
        //Post item
        if (formData.name !== "" && formData.color !== "" && formData.idGame !== "") {
            let formDataAux = { ...formData, game: { id: formData.idGame } };
            HttpConnection(`${HttpOperations.section}${formData.id ? `/${formData.id}` : ''}`,
                result => resultSuccess(),
                error => {
                    setIsSending(false);
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                }, formData.id ? HttpMethod.put : HttpMethod.post, formDataAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const resultSuccess = () => {
        //Refresh data
        refreshItems();
        //Notify user
        dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
    }

    return <Card flex onClick={e => e.stopPropagation()} width="12" lg="6" mv="8" pb="8" color="color-gray-card">
        <Container width="12" fontWeight="bold" mx="16" mt="32" fontSize="26px" textAlign="left" children={<TextLocalized children="msgSectionTitle" />} />
        <Container width="12" ph="16" pb="16" mt="16" children={<CustomInput onChange={onChange} titleph="16" title={"msgName"} placeholder={dictionary["msgName"]} type="text" name="name" value={formData.name} />} />
        <Container width="12" ph="16" pb="16" children={<AreaInput onChange={onChange} titleph="16" title={"msgDescription"} placeholder={dictionary["msgDescription"]} type="text" name="color" value={formData.color ?? ""} />} />

        <ContainerFlex width="12" ph="16" align="center">
            <SelectItemInput onChange={onChange} titleph="16" title={"msgGame"} placeholder={dictionary["actionSelectGame"]} name="idGame" value={formData.idGame ?? ""} options={games.map(game => {
                return { key: game.name, value: game.id };
            })} />
        </ContainerFlex>
        <ButtonFlat isSending={isSending} width="wrap" mx="16" action={sendRequest} label="actionSave" fontColor="color-white" />
    </Card>
}

export default SectionForm;