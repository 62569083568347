import React, { Fragment, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UserAction, UserContext } from './providers/UserProvider';
import { Button, Container, ContainerFlex, LanguageSelector, TextLocalized } from './styled/Styled';

import logo from '../img/logo.png';

const Header = () => {
    let history = useNavigate();
    let location = useLocation();
    let { state, dispatch } = useContext(UserContext);

    const fontWeight = (word) => location.pathname.includes(word) ? "600" : "300";

    const openUrl = (url) => {
        window.open(url, "_blank");
    }

    return (
        <Fragment>
            <ContainerFlex width="12" ph="32" color="color-white" fontColor="color-black" align="center" justify="space-between">
                <Button flex align="center" onClick={() => history("/")} fontWeight={location.pathname === "/" || location.pathname === "" ? "600" : "300"} mh="4" px="16" textAlign="center">
                    <img alt="efic-logo" src={logo} height="48px" />
                </Button>
                <ContainerFlex align="center">
                    <Button onClick={() => openUrl("https://linqoz.es")} fontWeight={fontWeight("login")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgHeader1" />} />
                    <Button onClick={() => openUrl("https://futbolinqoz.linqoz.es")} fontWeight={fontWeight("goodpractices")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgHeader2" />} />
                    {
                        state.login ?
                            <Fragment>
                                <Button onClick={() => history("/profile")} fontWeight={fontWeight("profile")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgHeader3" />} />
                                <Button onClick={() => dispatch({ action: UserAction.logout })} mh="4" ph="16" pv="4" color="color-black" hoverColor={`color-black-dark`} fontColor="color-white" textAlign="center" children={<TextLocalized children="actionExit" />} />
                            </Fragment>
                            :
                            <Button onClick={() => history("/login")} mh="16" ph="16" pv="4" color="color-black" hoverColor={`color-black-dark`} fontColor="color-white" textAlign="center" children={<TextLocalized children="msgHeader4" />} />
                    }
                    <LanguageSelector />
                </ContainerFlex>
            </ContainerFlex>
            <Container width="12" height="1px" color="color-gray" opacity="0.5" />
        </Fragment>

    );
};

export default Header;